import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Typography, CircleLoader } from '@hpstellar/core';
import { Search, Cross } from '@hpstellar/icons';
import debounce from 'lodash/debounce';

import { Helpers } from '../../core/src/helpers';
import AutocompleteContainer from './autocomplete-container';

import useHawksearchClientData from '../../search/useHawksearchClientData';
import useSearchEndpoint from '../../search/useSearchEndpoint';
import useSuppliesSearch from '../useSuppliesSearch';

import './supplies-search.less';

export default function SuppliesSearch({ defaultSearch = '', productGroups, autocompleteCount = 6 }) {
    const [searchApi] = useSearchEndpoint('suppliesfinder');
    const clientData = useHawksearchClientData();
    const { search, isLoading, clearResults, setLatestSearch } = useSuppliesSearch();

    const [query, setQuery] = useState(defaultSearch);
    const searchInput = useRef(null);
    const [results, setResults] = useState(null);

    const handleChange = value => {
        setQuery(value);
        if (value === '') {
            setResults(null);
            clearResults();
        }
    };

    const debouncedSearch = useMemo(() => debounce(handleChange, 200), []);

    const onClearSearch = useCallback(() => {
        setQuery('');
        searchInput.current.value = '';
        setResults(null);
        clearResults();
    });

    const onAutocompleteSelect = keyword => {
        setLatestSearch(keyword);
        search(keyword, productGroups);
        setResults([]);
    };

    useEffect(() => {
        if (defaultSearch) {
            searchInput.current.value = defaultSearch;
        }
    }, [searchInput]);

    useEffect(() => {
        if (!query) {
            return () => {};
        }

        clearResults();

        searchApi.autocomplete(query, clientData, { Type: 'Product', ProductCount: autocompleteCount }).then(resp => {
            const { data } = resp;
            const reqData = JSON.parse(resp?.config?.data);
            if (reqData.Keyword !== searchInput.current.value) {
                return [];
            }

            const isMatch = data?.Products?.filter(p => {
                const sku = p?.Sku.toLowerCase();
                const term = query.toLowerCase();
                return sku === term || Helpers.removeSKULocalization(sku) === term;
            });

            if (isMatch.length === 1) {
                onAutocompleteSelect(isMatch?.[0]?.Sku);
                return;
            }

            const commonIds = data?.Products?.map(p =>
                p?.Results?.Document?.selectability?.[0]
                    ?.toLowerCase()
                    ?.split('/')
                    ?.map(s => s.replace(/\D/g, '')),
            );
            const allMatch = commonIds
                ?.sort((a, b) => b.length - a.length)
                ?.slice(1)
                ?.every(arr => arr?.every(id => commonIds?.[0]?.includes(id)));
            // its ink family search if there is a common id among the results
            if (allMatch) {
                onAutocompleteSelect(query);
                return () => {};
            }

            let paperTerm = query;
            for (const p of data?.Products || []) {
                if (p?.Results?.Document?.prnsuptype?.[0] !== 'paper') {
                    paperTerm = '';
                    break;
                }
            }
            if (paperTerm !== '') {
                onAutocompleteSelect(paperTerm);
                return () => {};
            }

            const isSinglePrinter =
                data?.Products.length === 1 &&
                (data?.Products[0]?.Results?.Document?.product_type?.[0]?.toLowerCase() === 'printer' ||
                    data?.Products[0]?.Results?.Document?.product_type?.[0]?.toLowerCase() === 'printers');

            if (isSinglePrinter) {
                onAutocompleteSelect(data?.Products[0]?.Results?.Document?.sku?.[0]);
                return () => {};
            }

            setResults({ products: data.Products });
        });
    }, [query]);

    return (
        <div className="suppliesSearch">
            <div className="suppliesSearch-icon-container">
                <div className="suppliesSearch-cross-icon">
                    {isLoading ? (
                        <CircleLoader className="suppliesSearch-loader" size="30px" />
                    ) : (
                        <Cross
                            style={query ? { display: 'block' } : { display: 'none' }}
                            onClick={() => onClearSearch(null)}
                        />
                    )}
                </div>

                <Search className="suppliesSearch-search-icon" />
            </div>
            <Typography variant="bodyM">
                <input
                    type="text"
                    ref={searchInput}
                    className="suppliesSearch-input"
                    placeholder="Search by printer name, model, or cartridge #"
                    onFocus={e => {
                        if (!e.target.value) e.target.value = '';
                    }}
                    onChange={e => debouncedSearch(e.target.value)}
                />

                <AutocompleteContainer
                    results={results}
                    onAutocompleteSelect={onAutocompleteSelect}
                    searchTerm={query}
                />
            </Typography>
        </div>
    );
}
