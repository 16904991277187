import React from 'react';
import { Typography } from '@hpstellar/core';
import useDeviceInfo from '../../hooks/useDeviceInfo';
import { ShippingChecked } from '@hpstellar/icons';
import { getIconWithSVG } from '../../category-landing-page/components/shared';
import withPageComponents from '../../page/components/with-page-components';
import { Helpers } from '../../core/src/helpers';

import './title-container.less';

export default withPageComponents(
    ({ suppliesSearch, disableIcon, templateId }) => {
        const { device } = useDeviceInfo(['device']);
        const { title, titleMobile, subtitle, subtitleMobile, icon } = suppliesSearch || {};
        const displayTitle = device === 'mobile' && titleMobile ? titleMobile : title;

        const displaySubtitle = device === 'mobile' && subtitleMobile ? subtitleMobile : subtitle;
        const displayShippingIcon = suppliesSearch && !disableIcon && !icon && /free shipping/gi.test(displaySubtitle);

        const showSubTitle =
            !suppliesSearch?.promoBar ||
            suppliesSearch?.promoBar?.filter(bar => bar.templates.includes(templateId)).length < 1;

        return (
            <div className="suppliesFinderTitle">
                {displayTitle && (
                    <Typography
                        className="suppliesFinderTitle-title"
                        tag="h1"
                        dangerouslySetInnerHTML={Helpers.createMarkup(displayTitle)}
                    />
                )}
                {showSubTitle && (
                    <div className="suppliesFinderTitle-subtitle-container">
                        {displayShippingIcon && <ShippingChecked aria-label="Shipping" />}
                        {!displayShippingIcon && icon && (
                            <span className="custom-icon">{getIconWithSVG({ icon })}</span>
                        )}
                        {displaySubtitle && (
                            <Typography
                                tag="p"
                                className="suppliesFinderTitle-subtitle fontLight"
                                dangerouslySetInnerHTML={Helpers.createMarkup(displaySubtitle)}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    },
    { components: ['suppliesSearch'] },
);
