/* eslint-disable react/no-danger */
import React, { useState } from 'react';

import { PriceBlock } from '@hpstellar/core';
import { Dropdown, Button, Typography } from '@hpstellar/core/visId';
import { Info } from '@hpstellar/icons';
import { XFilled } from '@hpstellar/veneer-icons';

import { RenderEspotHtml } from './espot-manager';
import { Helpers } from '../../core/src/helpers';
import { getGTMClickAttributes } from '../../metrics/metrics-helpers';
import { useMSRPProps } from '../../hooks/useProductPrice';

import './instant-ink.less';

export default function InstantInk({ logoPNG, message, options, ctaText, ctaLink, eSpotName }) {
    const [ink, setInk] = useState(null);
    const [expand, setExpand] = useState(false);
    const msrp = useMSRPProps();

    return (
        <div className="instantInk">
            <div className="instantInk-Logo">
                <img src={logoPNG} alt="instant ink" />
                <Typography className="instantInk-msg" variant="list">
                    <span dangerouslySetInnerHTML={Helpers.createMarkup(message)} />
                </Typography>
            </div>

            <div className="instantInk-body">
                <Dropdown
                    className="instantInk-dropdown"
                    label="Ink Plans"
                    options={options.map(option => ({ label: option.label, value: option.value }))}
                    onChange={val => setInk(val)}
                    variation="form"
                    listProps={{ matchWidth: true }}
                />
                <Button
                    className="instantInk-details-btn"
                    variation="tertiary"
                    color="dark"
                    size="small"
                    endIcon={expand ? <XFilled size="16" /> : <Info size="s" />}
                    onClick={() => setExpand(!expand)}
                    {...getGTMClickAttributes('linkClick', 'instant-ink', `view-details`)}
                >
                    {expand ? 'Close details' : 'Details'}
                </Button>
            </div>

            <div className="instantInk-footer">
                <div>
                    <Typography className="instantInk-price" weight="700">
                        {!ink && <span className="instantInk-price-label">From</span>}
                        <PriceBlock
                            className="instantInk-price-block"
                            regularPrice={ink || options?.[0]?.value}
                            salePriceSuffix="/mo."
                            style="right"
                            size="small"
                            weight="700"
                            msrp={msrp}
                        />
                    </Typography>
                </div>
                <div className="instantInk-cta">
                    <Button to={ctaLink} theme="dark" size="small">
                        {ctaText}
                    </Button>
                </div>
            </div>

            <RenderEspotHtml eSpotName={eSpotName} details expand={expand} />
        </div>
    );
}
