import React from 'react';

import { Typography, Link, Ratings } from '@hpstellar/core';
import { InkCartridges } from '@hpstellar/icons';

import './product-card.less';

export default function ProductCard({ product }) {
    const { name, images, cartridge_replacement_note: notification, rating, numReviews } = product;
    const { src: image } = images?.[0] || {};

    return (
        <div className="suppliesRow-col supplies-product-card">
            <div>
                <div className="supplies-product-card-image-box">
                    {image && image !== 'null' && <img src={image} alt={name} />}
                    {(!image || image === 'null') && <InkCartridges />}
                </div>
            </div>
            <div className="supplies-product-card-content">
                {notification && (
                    <Typography className="supplies-product-card-notification" responsive tag="h3" variant="boldS">
                        {notification}
                    </Typography>
                )}
                <Link
                    className="supplies-product-card-title"
                    color="dark"
                    size="small"
                    variation="body"
                    weight="light"
                    to={product?.productUrl}
                >
                    {name}
                </Link>
                {rating && numReviews && <Ratings score={rating} reviewsCount={numReviews} />}
            </div>
        </div>
    );
}
