import React from 'react';

import { Typography } from '@hpstellar/core';
import { ShippingChecked } from '@hpstellar/icons';
import { getIcon } from '../../category-landing-page/components/shared';

import './shipping-tile.less';

export default function ShippingTile({ shippingMessage, group, deliveryDate, isMobile, children }) {
    let { icon: iconName, msg, subMsg } = shippingMessage || {};

    let icon = null;
    // TODO: remove after transition to object msg
    if (typeof shippingMessage === 'string') {
        if (/shipping/gi.test(shippingMessage)) {
            icon = <ShippingChecked />;
        }
        msg = shippingMessage;
    }
    if (typeof shippingMessage === 'object' && msg) {
        icon = getIcon(iconName);
    }

    return (
        <div
            className={`suppliesRow-col supplies-shipping-tile${isMobile ? ' supplies-shipping-tile-mobile' : ' supplies-shipping-tile-desktop'}`}
        >
            {shippingMessage && group.title.trim() !== 'INK REPLACEMENT SERVICE' && (
                <div className="supplies-shipping-tile-header">
                    {icon}
                    <Typography className="supplies-shipping-tile-offers" tag="p" variant="boldS">
                        {msg}
                    </Typography>
                    {subMsg && (
                        <Typography className="supplies-shipping-tile-subMsg" tag="p">
                            {subMsg}
                        </Typography>
                    )}
                    {children}
                </div>
            )}
        </div>
    );
}
