import React from 'react';

import { Link, Container } from '@hpstellar/core/visId';

import usePageComponents from '../../../hooks/usePageComponents';
import { getIcon } from '../shared';

import './assurance-bar.less';

export default function AssuranceBar({ mobile, templateId = 'cat' }) {
    const { suppliesSearch } = usePageComponents(['suppliesSearch'], true);
    const { promoBar } = suppliesSearch || {};

    if (!promoBar) return null;
    const catLinks = promoBar?.filter(item => item.templates.includes(templateId));
    if (catLinks?.length < 1) return null;

    return (
        <div className={`assuranceBar${mobile ? ' assuranceBar-mobile' : ' assuranceBar-sticky'}`}>
            <Container className="assuranceBar-container">
                {catLinks.map(({ icon, url, title, titleMobile }) => (
                    <div className="assuranceBar-item">
                        {getIcon(icon, null, '24')}

                        {!url && <span>{title}</span>}
                        {url && (
                            <Link theme="dark" to={url}>
                                {mobile ? titleMobile : title}
                            </Link>
                        )}
                    </div>
                ))}
            </Container>
        </div>
    );
}
