import React from 'react';

import { Typography } from '@hpstellar/core';

import './header-type.less';

export default function HeaderType({ title, labels, group, printers }) {
    // hide CTA labels
    const labelsList = labels.map(l => (l === 'CTA' ? '' : l));
    const override = group?.titlePrinterOverride;
    const headerTitle = printers?.length > 0 ? override || `Compatible ${title}` : title;

    return (
        <div className="suppliesFinderHeaderType">
            <Typography className="suppliesFinderHeaderType-col" style={{ flex: 2 }} variant="bodyM">
                {headerTitle}
            </Typography>
            {labelsList.map((label, index) => (
                <Typography
                    id={`colLabel-${index}`}
                    key={`${label}_${index}`}
                    className={`suppliesFinderHeaderType-col suppliesFinderHeaderType-${label}${
                        title?.toLowerCase() === 'toner' && label?.toLowerCase() === 'quantity'
                            ? ' suppliesFinderHeaderType-toner'
                            : ''
                    }`}
                    variant="bodyS"
                >
                    {label}
                </Typography>
            ))}
        </div>
    );
}
