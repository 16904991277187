import React, { useEffect } from 'react';

import { Accordion, AccordionContent } from '@hpstellar/core';

import { Helpers } from '../../core/src/helpers';
import ETRWCSApi from '../../core/src/etr-wcs-api-client';
import withPageComponents from '../../page/components/with-page-components';
import useUserData, { getPStoreID } from '../../hooks/useUserData';
import usePage from '../../hooks/usePage';

export const RenderEspotHtml = withPageComponents(
    ({ eSpotName, suppliesEspots, className, details, expand }) => {
        const content = suppliesEspots && suppliesEspots[eSpotName];
        if (!content) {
            return null;
        }

        const html = (
            <div className="suppliesRow-rich-html">
                <div className={className} dangerouslySetInnerHTML={Helpers.createMarkup(content)} />
            </div>
        );

        if (!details) {
            return html;
        }

        return (
            <Accordion className="suppliesRow-details" iconType="caret" expanded={expand} onChange={() => {}}>
                <AccordionContent className="inkDetails-content">{html}</AccordionContent>
            </Accordion>
        );
    },
    {
        components: ['suppliesEspots'],
    },
);

/**
 * Espot Manager - Component to manage espot html data and dispatch to store
 * @param {*} props
 */
export default withPageComponents(
    ({
        vanityUrl,
        eSpotNames = ['Hawk_Instantink_Eligible_Content', 'Hawk_Subscription_Ink_Content'],
        suppliesSearch,
    }) => {
        const { userData } = useUserData();
        const { updateComponent } = usePage();
        const pStoreID = getPStoreID(userData);
        //fetch espots whenever vanity URL changes
        useEffect(() => {
            if (vanityUrl && suppliesSearch) {
                Promise.all(
                    eSpotNames.map(eSpotName =>
                        ETRWCSApi.component
                            .get('espot', eSpotName, { path: encodeURIComponent(vanityUrl), pStoreID })
                            .then(data => {
                                const response = data && data[eSpotName] && data[eSpotName][0];
                                const { content } = response || {};
                                return { eSpotName, content };
                            }),
                    ),
                ).then(resp => {
                    const components = {
                        suppliesEspots: resp.reduce((acc, { eSpotName, content }) => {
                            acc[eSpotName] = content;
                            return acc;
                        }, {}),
                    };
                    updateComponent(components);
                });
            }
        }, [vanityUrl, pStoreID, suppliesSearch]);

        return null;
    },
    { components: ['suppliesSearch'] },
);
